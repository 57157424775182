import {Row, Col} from "antd";
import {withTranslation} from "react-i18next";
import {SvgIcon} from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
    FooterSection,
    Title,
    NavLink,
    Extra,
    LogoContainer,
    Para,
    Large,
    Chat,
    Empty,
    FooterContainer,
    Language,
    Label,
    LanguageSwitch,
    LanguageSwitchContainer,
} from "./styles";
import {useContext} from "react";
import {ThemeContext} from "../../context/ThemeContext";

interface SocialLinkProps {
    href: string;
    src: string;
    alt: string;
}

const Footer = ({t}: any) => {
    const handleChange = (language: string) => {
        i18n.changeLanguage(language);
    };

    const SocialLink = ({href, src, alt}: SocialLinkProps) => {
        return (<a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                key={src}
                aria-label={src}
            >
                <SvgIcon src={src} width="36px" height="36px" alt={alt}/>
            </a>);
    };

    const {theme} = useContext(ThemeContext);

    return (<>
            <FooterSection>
                <Container>
                    <Row justify="space-between">
                        <Col lg={10} md={10} sm={12} xs={12}>
                            <Language>{t("Contact")}</Language>
                            <Large to="/">{t("Tell us everything")}</Large>
                            <Para>
                                {t(`Do you have any question? Feel free to reach out.`)}
                            </Para>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={12}>
                            <Title>{t("Policy")}</Title>
                            <Large to="/" left="true">
                                {t("Application Security")}
                            </Large>
                            <Large left="true" to="/">
                                {t("Software Principles")}
                            </Large>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <a href="mailto:info@rentadriver.rs">
                                <Chat>{t(`Let's Chat`)}</Chat>
                            </a>
                            <Empty/>
                            <Large left="true" to="/">
                                {t("Support Center")}
                            </Large>
                            <Large left="true" to="/">
                                {t("Customer Support")}
                            </Large>
                        </Col>
                    </Row>
                    <Row justify="space-between">
                        <Col lg={10} md={10} sm={12} xs={12}>
                            <Empty/>
                            <Language>{t("Address")}</Language>
                            <Para>Plavinci 88A</Para>
                            <Para>11306 Belgrade</Para>
                            <Para>Serbia</Para>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={12}>
                            <Title>{t("Company")}</Title>
                            <Large left="true" to="/">
                                {t("About")}
                            </Large>
                            <Large left="true" to="/">
                                {t("Press")}
                            </Large>
                            <Large left="true" to="/">
                                {t("Careers & Culture")}
                            </Large>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <Label htmlFor="select-lang">{t("Language")}</Label>
                            <LanguageSwitchContainer>
                                <LanguageSwitch onClick={() => handleChange("en")}>
                                    <SvgIcon
                                        src="united-states.svg"
                                        alt="The national flag of the United States of America, often referred to as the American flag or the U.S. flag"
                                        aria-label="homepage"
                                        width="30px"
                                        height="30px"
                                    />
                                </LanguageSwitch>
                                <LanguageSwitch onClick={() => handleChange("sr")}>
                                    <SvgIcon
                                        src="serbian.svg"
                                        alt="The flag of Serbia also known as the Tricolour"
                                        aria-label="homepage"
                                        width="30px"
                                        height="30px"
                                    />
                                </LanguageSwitch>
                            </LanguageSwitchContainer>
                        </Col>
                    </Row>
                </Container>
            </FooterSection>
            <Extra>
                <Container border={true}>
                    <Row
                        justify="space-between"
                        align="middle"
                        style={{paddingTop: "3rem"}}
                    >
                        <NavLink to="/">
                            <LogoContainer>
                                <SvgIcon
                                    src={'logo.png'}
                                    alt=""
                                    aria-label="homepage"
                                    width="121px"
                                    height="74px"
                                />
                            </LogoContainer>
                        </NavLink>
                        <FooterContainer>
                            <SocialLink
                                href="https://instagram.com/rent_a_driver_beograd?igshid=YmMyMTA2M2Y="
                                src={theme === "light-theme" ? "instagram-light.svg" : "instagram-dark.svg"}
                                alt="Instagram icon"
                            />
                            <SocialLink
                                href="https://twitter.com/RentaDriverBG"
                                src={theme === "light-theme" ? "twitter-light.svg" : "twitter-dark.svg"}
                                alt="Twitter icon"
                            />
                            <SocialLink
                                href="https://www.linkedin.com/company/rent-a-driver-beograd/"
                                src={theme === "light-theme" ? "linkedin-light.svg" : "linkedin-dark.svg"}
                                alt="LinkedIn icon"
                            />
                            <SocialLink
                                href="https://www.facebook.com/rentadriverbeogradsafedriver"
                                src={theme === "light-theme" ? "facebook-dark.svg" : "facebook-light.svg"}
                                alt="Facebook icon"
                            />
                        </FooterContainer>
                    </Row>
                </Container>
            </Extra>
        </>);
};

export default withTranslation()(Footer);
