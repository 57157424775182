import { SvgIconProps } from "../types";
import { useState, useEffect } from "react";

export const SvgIcon = ({
  placeholderSrc,
  src,
  height,
  width,
  alt,
}: SvgIconProps) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc);
  // console.log(imgSrc);

  useEffect(() => {
    const img = new Image();
    img.src = `/img/svg/${src}`;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  return (
    <img {...{ src: `/img/svg/${imgSrc}`, width, height }} alt={alt || src} />
  );
};
