import {useState, useContext} from "react";
import {Row, Col, Drawer} from "antd";
import {withTranslation} from "react-i18next";
import Container from "../../common/Container";
import {SvgIcon} from "../../common/SvgIcon";
import {Button} from "../../common/Button";
import {
    HeaderSection, LogoContainer, Burger, NotHidden, Menu, CustomNavLinkSmall, Label, Outline, Span,
} from "./styles";
import {ThemeContext} from "../../context/ThemeContext";

const Header = ({t}: any) => {
    const [open, setVisibility] = useState(false);

    const {theme, toggleTheme} = useContext(ThemeContext);

    const showDrawer = () => {
        setVisibility(!open);
    };

    const onClose = () => {
        setVisibility(!open);
    };

    const MenuItem = () => {
        const scrollTo = (id: string) => {
            const element = document.getElementById(id) as HTMLDivElement;
            element.scrollIntoView({
                behavior: "smooth",
            });
            setVisibility(false);
        };
        return (<>
                <CustomNavLinkSmall onClick={() => scrollTo("about")}>
                    <Span>{t("About")}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
                    <Span>{t("Mission")}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall onClick={() => scrollTo("product")}>
                    <Span>{t("Product")}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall
                    style={{width: "180px"}}
                    onClick={() => scrollTo("contact")}
                >
                    <Span>
                        <Button>{t("Contact")}</Button>
                    </Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall
                    onClick={() => {
                        toggleTheme();
                    }}
                >
                    <Span>
                        <SvgIcon
                            src={theme === "light-theme" ? "moon-svgrepo-com.svg" : "sun-svgrepo-com.svg"}
                            alt="Yellow sun icon which is used to switch to light theme"
                            aria-label="homepage"
                            width="35px"
                            height="35px"
                        />
                    </Span>
                </CustomNavLinkSmall>
            </>);
    };

    return (<HeaderSection>
            <Container>
                <Row justify="space-between">
                    <LogoContainer to="/" aria-label="homepage">
                        <SvgIcon src={theme === "light-theme" ? "logo.png" : "logo.png"}
                                 alt="Moon icon which is used to switch to dark theme"
                                 width="120px"
                                 height="70px"/>
                    </LogoContainer>
                    <NotHidden>
                        <MenuItem/>
                    </NotHidden>
                    <Burger onClick={showDrawer}>
                        <Outline/>
                    </Burger>
                </Row>
                <Drawer closable={false} open={open} onClose={onClose}>
                    <Col style={{marginBottom: "2.5rem"}}>
                        <Label onClick={onClose}>
                            <Col span={12}>
                                <Menu>Menu</Menu>
                            </Col>
                            <Col span={12}>
                                <Outline/>
                            </Col>
                        </Label>
                    </Col>
                    <MenuItem/>
                </Drawer>
            </Container>
        </HeaderSection>);
};

export default withTranslation()(Header);