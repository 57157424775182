import {createContext, useState, useEffect} from "react";

export const ThemeContext = createContext();

const getStorageTheme = () => {
    let theme = "dark-theme";
    if (localStorage.getItem("theme")) {
        theme = localStorage.getItem("theme");
    }
    return theme;
};

const ThemeProvider = ({children}) => {
    const [theme, setTheme] = useState(getStorageTheme());

    const toggleTheme = () => {
        if (theme === "light-theme") {
            setTheme("dark-theme");
        } else {
            setTheme("light-theme");
        }
    };

    useEffect(() => {
        document.documentElement.className = theme;
        localStorage.setItem("theme", theme);
    }, [theme]);

    return (<ThemeContext.Provider value={{theme, setTheme, toggleTheme}}>
            {children}
        </ThemeContext.Provider>);
};

export default ThemeProvider;
