import {createGlobalStyle} from "styled-components";

export const Styles = createGlobalStyle`

  .light-theme {
    --clr-bcg: #fff;
    --clr-font: #282c35; // --> kad promenimo u belo consent bude top al se ne vidi ostalo
    --clr-font-contact: #000;
    --clr-font-contact-focus: #000;
  }

  /* dark theme for the page */
  .dark-theme {
    --clr-bcg: rgba(40, 44, 53, 0.85);
    --clr-font: #fff;
    --clr-font-contact: #000;
    --clr-font-contact-focus: #fff;
  }

  @font-face {
    font-family: "Motiva Sans Light";
    src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
    font-style: normal;
  }

  @font-face {
    font-family: "Motiva Sans Bold";
    src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
    font-style: normal;
  }


  body,
  html,
  a {
    font-family: 'Motiva Sans Light', sans-serif;
  }


  body {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: var(--clr-bcg);
    overflow-x: hidden;
  }

  a:hover {
    color: #6f8f16;
  }

  input,
  textarea {
    border-radius: 4px;
    border: 0;
    background: rgb(241, 242, 243);
    transition: all 0.3s ease-in-out;
    outline: none;
    width: 100%;
    padding: 1rem 1.25rem;
    color: var(--clr-font-contact);

    ::placeholder {
      color: var(--clr-font-contact);
    }

    :focus-within {
      background: none;
      box-shadow: #6f8f16 0px 0px 0px 1px;
      color: var(--clr-font-contact-focus);

      ::placeholder {
        color: var(--clr-font-contact-focus);
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Motiva Sans Bold', serif;
    color: #02a54f;
    font-size: 56px;
    line-height: 1.18;

    @media only screen and (max-width: 890px) {
      font-size: 47px;
    }

    @media only screen and (max-width: 414px) {
      font-size: 32px;
    }
  }

  p {
    color: var(--clr-font);
    font-size: 21px;
    line-height: 1.41;
  }

  h1 {
    font-weight: 600;
  }

  a {
    text-decoration: none;
    outline: none;
    color: #02a54f;

    :hover {
      color: #6f8f16;
    }
  }

  *:focus {
    outline: none;
  }

  .about-block-image svg {
    text-align: center;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 1.5rem;
    background: var(--clr-bcg);

    svg {
      fill: #02a54f;
    }
  }

  .ant-drawer-content-wrapper {
    width: 300px !important;
  }
`;
